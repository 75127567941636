<!--
 * @Description: 商品分类一级分类Form页面
 * @Author: 小广
 * @Date: 2019-06-05 13:37:27
 * @LastEditors: 蒙川
 * @LastEditTime: 2019-08-02 19:06:53
 -->
<template>
  <div class="goodsCategoryForm-wrapper">
    <form-panel
      ref="formPanel"
      :form="form"
      v-bind="submitConfig"
      @update="update"
    >
      <col2-detail>
        <col2-block >
          <el-form-item label="一级类目">
             <span >{{detailItem.oneCategoryName}}</span>
          </el-form-item>
          <el-form-item label="分类名称">
              <div class="content-wrapper">
              <v-input
                  v-model="form.categoryAlias"
                  placeholder="分类名称"
                  :maxlength="4"
              ></v-input>
              <span class="right-tip">注：最多输入4位，为空时按照类目名称显示</span>
            </div>
          </el-form-item>
          <el-form-item label="排序">
              <div class="content-wrapper">
              <v-input-number
                  v-model="form.sort"
                  placeholder="请输入排序"
                  :min="1"
                  :max="9999"
              ></v-input-number>
              <span class="right-tip">注：为空时按照默认条件进行排序</span>
            </div>
          </el-form-item>
          <el-form-item
            label="状态"
            prop="showStatus"
            :rules="{ required: true, message: '当前选项不允许为空', trigger: 'change' }"
          >
            <v-select
              v-model="form.showStatus"
              :options="statusOps"
            ></v-select>
          </el-form-item>
           <el-form-item 
            :rules="[{required: true,message: '当前选项不允许为空',trigger: 'change'}]"
            label="启用预约" 
            
          >
            <v-select
              v-model="form.serviceType"
              :options="serviceTypeOps"
              :width="width"
            ></v-select>
          </el-form-item>
        </col2-block>
      </col2-detail>
    </form-panel>
  </div>
</template>

<script>
import { getDetailURL, editURL } from './api'
import { statusOps , serviceTypeOps} from './map'
import { Col2Block, Col2Detail } from 'components/bussiness'

export default {
  name: '',
  components: {
    Col2Block,
    Col2Detail
  },
  data () {
    return {
      width: 180,
      submitConfig: {
        queryUrl: getDetailURL,
        submitUrl: editURL,
        submitMethod: 'put'
      },
      serviceTypeOps,
      // 除去全部
      statusOps: statusOps.slice(1, statusOps.length),
      form: {
        id: this.$route.query.id,
        categoryAlias: '',
        showStatus: 1,
        sort: undefined,
        serviceType:0
      },
      detailItem: {
        oneCategoryName: ''
      }
    }
  },

  mounted () {
    const { id, regionName } = this.$route.query
    this.$refs.formPanel.getData({ id })
    this.$setBreadcrumb(`${regionName} > 编辑`)
  },

  methods: {

    update (data) {
      Object.keys(this.detailItem).forEach(key => {
        if (data[key]) {
          this.detailItem[key] = data[key]
        }
      })

      Object.keys(this.form).forEach(key => {
        if (data[key]) {
          this.form[key] = data[key]
        }
      })

      this.$set(this.form, 'showStatus', parseInt(data.showStatus))
    }
  }
}
</script>

<style lang="scss" scoped>
.goodsCategoryForm-wrapper {
  .content-wrapper {
    display: flex;
    justify-content: flex-start;

    .right-tip {
      margin-left: 15px;
    }
  }
}
</style>
